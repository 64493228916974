import { apiProvider } from "../api/core/provider";
import { DateTime } from "luxon";
import { DATE_MED_WITH_WEEKDAY } from "luxon/src/impl/formats";
import { config } from "../core";
import { useMemo } from "react";

export const postNewNote = async (policyNo, note) => {
  console.log("Posting new note " + note);
  return await apiProvider.post("inspection/" + policyNo + "/history/new", note);
};

export const statusToText = status => {
  switch (status) {
  case "inprogress":
    return "In Progress";
  case "onhold":
    return "On Hold";
  case "noaccess":
    return "No Access";
  default:
    return titleCase(status);
  }
};


export const titleCase = str => {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const isClosed = status => {
  return status === "submitted" || status === "cancelled";
};

export const isCancelled = status => {
  return status === "cancelled";
};

export const requiresInterior = typeCode => {
  return typeCode === "A" || typeCode === "B++";
};

export const isAppointmentSet = (date) => {
  return date !== "0001-01-01T00:00:00Z";
};

export const appointmentToString = (date, time) => {
  // 'date' is only a Date with no Time component, but it will end it 00:00:00Z as that is the ISO format
  // we need to remove the trailing Timezone specifier ('Z') and then DateTime.fromISO will use the browser's Tiemzone instead of UTC and so the represented
  // Date will not change

  if (date.endsWith('Z')) {
    date = date.substring(0,date.length-1);
  }
  let dt = DateTime.fromISO(date);
  let tm = appointmentTimes(true).find(t => t.id === time).name;
  if (!tm) {
    tm = 'All day';
  }

  return `${dt.toLocaleString(DATE_MED_WITH_WEEKDAY)} ${tm}`;
};

export const roundToXDecimalPlaces = (num, decimalPlaces) => {
  return Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
};

// Update the metersToMiles function to use the rounding helper
export function metersToMiles(meters) {
  const miles = meters * 0.000621371;
  return roundToXDecimalPlaces(miles,1);
}

export const appointmentTimes = (all = false) => {

  if (all) {
    let apptTimes = [
      { id: 0, name: "All day" },
      { id: 1, name: "AM" },
      { id: 2, name: "Midday" },
      { id: 3, name: "PM" }
    ];

    for (let i = 0; i < config.appointmentTimes.length; i++) {
      apptTimes.push({ id: config.appointmentTimes[i].Index, name: config.appointmentTimes[i].Name });
    }
    return apptTimes;
  }

  if (config.useNewAppointmentTimes) {
    return config.appointmentTimes.map((time, index) => {
      return { id: time.Index, name: time.Name };
    });
  } else {
    return [
      { id: 1, name: "AM" },
      { id: 2, name: "Midday" },
      { id: 3, name: "PM" }
    ];
  }
};