import React, { useEffect, useMemo, useRef, useState } from 'react';
import { apiInspectors } from "../api/inspectors";
import { apiInspections } from "../api/inspections";
import { Loader } from 'google-maps';
import { formatTime, parseDateTimeAndChangeToLocalTimezone, secondsToDate } from "../utilities/dateTime";
import { appointmentToString, metersToMiles } from "../utilities/inspections";
import FSButton from "../components/Buttons/FSButton";
import { useMediaQuery } from "react-responsive";
import { getRoles } from "../api/core/provider";
import * as PropTypes from "prop-types";
import { getTextColorForBackground } from "../utilities/colours";
import ReactDOMServer from 'react-dom/server';
import ColorKey from "../components/Misc/ColorKey";

const formatDate = date => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

// Helper function to format duration in seconds to mm:ss
const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  return `${minutes}m`;
};

function secondsToTime(total_time) {
  const hours = Math.floor(total_time / 3600);
  const minutes = Math.floor(total_time % 3600 / 60);
  if (hours === 0) {
    return `${minutes}m`;
  }
  return `${hours}h ${minutes}m`;
}


function getTimeDifference(StartedAt, CompletedAt) {
  if (!StartedAt || !CompletedAt) {
    return "";
  }
  const startedAt = new Date(StartedAt);
  const completedAt = new Date(CompletedAt);
  const difference = Math.abs(completedAt - startedAt) / 1000;
  return secondsToTime(difference);
}


function getDateIfNotRouteDate(eventDate,routeDate) {
  const eventDateObj = new Date(eventDate);
  if (eventDateObj.toDateString() === routeDate.toDateString()) {
    return "";
  }
  return eventDateObj.toLocaleDateString();
}

function InspectionWorkingTimes({ inspection, selectedDate }) {
  if (!inspection) {
    return null;
  }

  if (inspection.StartedAt) {
    // this inspection is in a route, show all times
    return (
      <>
        <p>Appointment: {inspection.AppointmentTime}</p>
        <p>Started: {!inspection.StartedAt.startsWith("0001") ? formatTime(new Date(inspection.StartedAt)) : "--"} Completed: {!inspection.CompletedAt.startsWith("0001") ? formatTime(new Date(inspection.CompletedAt)) : "--"}
          {!inspection.StartedAt.startsWith("0001") && !inspection.CompletedAt.startsWith("0001") ? " (" + getTimeDifference(inspection.StartedAt, inspection.CompletedAt) + ")" : ""} {!inspection.CompletedAt.startsWith("0001") && getDateIfNotRouteDate(inspection.CompletedAt, selectedDate)}
        </p>
      </>
    );
  } else {
    // not in a route, just show appointment time
    return (
      <p>Appointment: {inspection.AppointmentTime}</p>
    );
  }
}

InspectionWorkingTimes.propTypes = {};

const Planner = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [inspectors, setInspectors] = useState([]);
  const [selectedInspector, setSelectedInspector] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDate, setSelectedDate] = useState(new Date("2024-10-08").toISOString().split("T")[0]);
  // const [selectedDate, setSelectedDate] = useState("2023-08-07");
  const [showInspectionsWithNoAppointment, setShowInspectionsWithNoAppointment] = useState(false);
  const [plannedInspections, setPlannedInspections] = useState(null);
  const [google, setGoogle] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapInfoWindow, setMapInfoWindow] = useState(null);
  const [startLocation, setStartLocation] = useState(null);
  const [endLocation, setEndLocation] = useState(null);
  const [recalculationInProgress, setRecalculationInProgress] = useState(false);
  const [selectedInspections, setSelectedInspections] = useState([]);
  const selectedInspectionsRef = useRef(selectedInspections);

  useEffect(() => {
    selectedInspectionsRef.current = selectedInspections;
  }, [selectedInspections]);

  const topSection = useRef();
  const mainDiv = useRef();
  const startLocationInput = useRef();
  const endLocationInput = useRef();
  const startTimeInput = useRef();
  const endTimeInput = useRef();

  const isManager = getRoles().includes("manager");

  const options = {
    version: "weekly",
    libraries: ["places", "geometry"]
  };
  const loader = new Loader('AIzaSyBHDVtQHIMXzOr7xjqadRZtScytVh__xfA', options);

  const consolidatedInspectionList = useMemo(() => {
    const consolidatedList = [];

    plannedInspections?.route?.RouteInspections?.forEach(inspection => {
      consolidatedList.push(inspection);
    });
    plannedInspections?.planned?.forEach(inspection => {
      if (!consolidatedList.find(i => i.PolicyNo === inspection.PolicyNo)) {
        consolidatedList.push(inspection);
      }
    });

    // console.log({ consolidatedList });

    return consolidatedList;
  }, [plannedInspections]);

  const selectedDateIsInThePast = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return selectedDate < today;
  },[selectedDate]);

  const getColorForInspection = inspection => {

    if (selectedInspections.includes(inspection.PolicyNo)) {
      return "blue";
    }

    if (["completed","submitted"].includes(inspection.Status)) {
      return "#00acdc";
    }

    const plannedInspection = plannedInspections?.route?.RouteInspections?.find(i => i.PolicyNo === inspection.PolicyNo);
    if (plannedInspection) {
      if (!plannedInspection.StartedAt?.startsWith("0001")) {
        return "orange";
      }
    }

    if (inspection.ArrivalTime) {
      return "green";
    }
    return "#777777";
    //
    // switch (inspection.type) {
    // case "A":
    //   return "red";
    // case "B":
    // case "B+":
    //   return "blue";
    // case "B++":
    //   return "green";
    // case "BR":
    //   return "orange";
    // default:
    //   return "yellow";
    // }
  };

  // const setMainDivSize = () => {
  //   if (isMobile) {
  //     return;
  //   }
  //   if (!mainDiv.current) {
  //     return;
  //   }
  //   const height = window.innerHeight - mainDiv.current.offsetTop - 20;
  //   mainDiv.current.style.height = `${height}px`;
  // };

  const setAppointment = async (inspectionId, time) => {
    const inspection = plannedInspections.find(i => i.id === inspectionId);
    const timeStr = time === 1 ? "AM" : time === 2 ? "Midday" : "PM";
    const newInspection = { ...inspection, inspection_time: timeStr };
    const updatedInspections = plannedInspections.map(i => i.id === inspectionId ? newInspection : i);
    setPlannedInspections(updatedInspections);
    // const updatedMarkers = markers.map(async marker => {
    //   if (marker.inspectionId === inspectionId) {
    //   // if (marker.position.lat === inspection.lat && marker.position.lng === inspection.lng) {
    //     marker.setMap(null);
    //     const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    //     return createMarker(AdvancedMarkerElement, newInspection);
    //   }
    //   return marker;
    // });
    // setMarkers(updatedMarkers);
    if (mapInfoWindow) {
      mapInfoWindow.close();
    }
  };

  const initMap = async () => {
    const google = await loader.load();
    setGoogle(google);
    const map1 = new google.maps.Map(document.getElementById('map1'), {
      center: { lat: 25.782479, lng: -80.2706578 },
      zoom: 8,
      mapId: '8041c9b566fb264'
    });
    const map2 = new google.maps.Map(document.getElementById('map2'), {
      center: { lat: 25.782479, lng: -80.2706578 },
      zoom: 8,
      mapId: '8041c9b566fb264'
    });
    setMap({ map1,map2 });

    setMapInfoWindow(new google.maps.InfoWindow());

    // set bounds from Austin TX to the Florida Keys
    const bounds = {
      north: 30.307982,
      south: 24.396308,
      east: -80.031362,
      west: -97.744383
    };
    const options = {
      bounds: new google.maps.LatLngBounds(bounds),
      componentRestrictions: { country: "us" },
      fields: ["geometry"],
      strictBounds: false,
    };

    const autocompleteStart = new google.maps.places.Autocomplete(startLocationInput.current, options);
    const autocompleteEnd = new google.maps.places.Autocomplete(endLocationInput.current, options);

    autocompleteStart.addListener("place_changed", () => {
      const place = autocompleteStart.getPlace();
      if (!place.geometry) {
        return;
      }
      setStartLocation(place.geometry.location.toJSON());
      // if autoCompleteEnd is empty, copy the address from autoCompleteStart
      if (endLocationInput.current.value === "") {
        endLocationInput.current.value = startLocationInput.current.value;
        setEndLocation(place.geometry.location.toJSON());
      }
    });
    autocompleteEnd.addListener("place_changed", () => {
      const place = autocompleteEnd.getPlace();
      if (!place.geometry) {
        return;
      }
      setEndLocation(place.geometry.location.toJSON());
    });

  };

  const selectInspection = (policyNo) => {
    if (selectedInspectionsRef.current.includes(policyNo)) {
      setSelectedInspections(selectedInspectionsRef.current.filter(i => i !== policyNo));
    } else {
      setSelectedInspections([...selectedInspectionsRef.current, policyNo]);
    }
  };

  useEffect(() => {
    const initData = async () => {
      const inspectors = await apiInspectors.get();
      setInspectors(inspectors.data.result);
    };

    initData();
    initMap();
    // window.onresize = () => {
    //   setMainDivSize();
    // };
    // setMainDivSize();

    if (!isManager) {
      setSelectedInspector(-1);
    }
  }, []);

  const updateInspections = async () => {
    const apiResults = await Promise.all([apiInspections.getPlannedInspections(selectedInspector, selectedDate.toISOString().split('T')[0], showInspectionsWithNoAppointment), apiInspections.getRoute(selectedInspector, selectedDate.toISOString().split('T')[0])]);
    const planned = apiResults[0].data.result;
    const route = apiResults[1].data.result;
    setPlannedInspections({ route,planned });

    startLocationInput.current.value = route.StartAddress;
    setStartLocation({ lat: route.StartLat, lng: route.StartLong });
    endLocationInput.current.value = route.EndAddress;
    setEndLocation({ lat: route.EndLat, lng: route.EndLong });
    if (route.DesiredStartTime && !route.DesiredStartTime.startsWith("0001")) {
      startTimeInput.current.value = route.DesiredStartTime.split("T")[1].substring(0, 5);
    } else {
      startTimeInput.current.value = "06:00";
    }
    if (route.DesiredEndTime && !route.DesiredEndTime.startsWith("0001")) {
      endTimeInput.current.value = route.DesiredEndTime.split("T")[1].substring(0, 5);
    } else {
      endTimeInput.current.value = "20:00";
    }
  };

  useEffect(() => {

    if (selectedInspector && selectedDate) {
      updateInspections();
    }
  }, [selectedInspector, selectedDate, showInspectionsWithNoAppointment]);

  useEffect(() => {
    updateMapBounds();
    // setMainDivSize();
  }, [map, consolidatedInspectionList,selectedInspections]);

  function markerClick(marker,inspection) {
    if (selectedInspectionsRef.current.length > 0) {
      selectInspection(inspection.PolicyNo);
      return;
    }

    let content = "";
    content = content + `<div>`;
    content = content + `<p class="tw-text-xl"><a class="" target="_blank" href="/inspections/${inspection.PolicyNo}/process/pre_r"}><strong class="tw-text-primary-500">${inspection.PolicyNo}</strong></a></p>`;
    content = content + `<p>${inspection.Insured}</p>`;
    content = content + `<p>${inspection.Address}<br/>${inspection.City}, ${inspection.County}, ${inspection.State} ${inspection.Zip}</p>`;
    content = content + `<br/`;
    content = content + `<p>Inspection Type: ${inspection.Type}</p>`;
    content = content + `<p>${inspection.Age} days old</p>`;
    content = content + `<br/`;
    content = content + `<p>Status: <strong>${inspection.PrettyStatus}</strong></p>`;
    // content = content + `<p>Assigned to: ${inspection.InspectorName ? inspection.InspectorName : "n/a"}</p>`;
    // content = content + `<p>Appointment Date: ${!inspection.AppointmentDate.includes("0001-01-01") ? parseDateTimeAndChangeToLocalTimezone(inspection.AppointmentDate).toLocaleDateString(undefined,{ month: 'short', day: 'numeric' }):"n/a"}</p>`;
    // content = content + `<div class="tw-mt-4 tw-flex tw-justify-center"><button class="tw-text-white tw-px-4 tw-py-2 tw-bg-primary-500" onclick="selectInspectionFunc('${inspection.PolicyNo}')">Select</button></div>`;

    // can't believe this hack actually works - call react function from plain js
    // window.setAppointmentFunc = setAppointment;
    // window.selectInspectionFunc = selectInspection;
    window.doCancelAppointmentFunc = doCancelAppointment;

    if (isManager) {
      content = content + `<div class="tw-mt-4 tw-flex tw-justify-center">`;
      content = content + `<button class="tw-text-white tw-px-4 tw-py-2 tw-bg-primary-500 tw-uppercase" onclick="window.doCancelAppointmentFunc('${inspection.PolicyNo}','${inspection.Address}')">Cancel Appt.</button>`;
      content = content + `</div>`;
    }
    content = content + `</div>`;

    mapInfoWindow.setContent(content);
    mapInfoWindow.open({
      anchor: marker, map: map.map1
    });
  }

  function createMarker(AdvancedMarkerElement, inspection, idx, mapToUse) {

    const markerTag = document.createElement("div");

    markerTag.className = "inspection-map-tag";
    switch (inspection.Type) {
    case "A":
      markerTag.classList.add("inspection-map-tag-a");
      break;
    case "B":
    case "B+":
      markerTag.classList.add("inspection-map-tag-bplus");
      break;
    case "B++":
      markerTag.classList.add("inspection-map-tag-bplusplus");
      break;
    case "BR":
      markerTag.classList.add("inspection-map-tag-br");
      break;
    default:
      markerTag.classList.add("inspection-map-tag-other");
    }

    if (inspection.AppointmentTime === "--") {
      markerTag.classList.add("inspection-map-tag-no-time");
    }

    if (inspection.ArrivalTime) {
      markerTag.textContent = Number(idx) + 1;
      markerTag.id = `insr-${inspection.PolicyNo}`;
    } else {
      markerTag.textContent = inspection.Type;
      markerTag.id = `ins-${inspection.PolicyNo}`;
    }

    markerTag.style.backgroundColor = getColorForInspection(inspection);
    markerTag.style.color = getTextColorForBackground(markerTag.style.backgroundColor);
    // font-size: 12px;
    //     padding: 8px 8px;
    markerTag.style.padding = "8px 8px";
    markerTag.style.fontSize = "0.8rem";

    const marker = new AdvancedMarkerElement({
      map: mapToUse,
      position: { lat: inspection.Lat, lng: inspection.Lng },
      content: markerTag,
    });

    marker.addListener("click", () => markerClick(marker, inspection));

    return marker;

    // const color = getColorForInspection(inspection);
    // const pinConfig = {
    //   background: color,
    //   borderColor: color,
    //   glyphColor: "white"
    // };
    //
    // const pin = new PinElement(pinConfig);
    // return pin;

  }

  function inspectionIsRouted(PolicyNo) {
    if (plannedInspections?.route?.RouteInspections?.find(i => i.PolicyNo === PolicyNo)) {
      return true;
    }
    return false;
  }

  const updateMapBounds = async () => {
    if (!map) {
      return;
    }
    if (!consolidatedInspectionList) {
      return;
    }
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    // markers.forEach(marker => marker.setMap(null));
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const markerList = [];
    if (map && plannedInspections) {
      const bounds = new window.google.maps.LatLngBounds();
      for (const i in plannedInspections?.route?.RouteInspections) {
        const inspection = plannedInspections.route.RouteInspections[i];
        const marker = createMarker(AdvancedMarkerElement, inspection, i, map.map1);
        markerList.push(marker);
        bounds.extend(marker.position);
      }
      map.map1.fitBounds(bounds);
    }
    if (map && plannedInspections) {
      const bounds = new window.google.maps.LatLngBounds();
      for (const i in plannedInspections?.planned) {
        const inspection = plannedInspections.planned[i];
        // if (!inspectionIsRouted(inspection.PolicyNo)) {
        const marker = createMarker(AdvancedMarkerElement, inspection, i, map.map2);
        markerList.push(marker);
        bounds.extend(marker.position);
        // }
      }
      map.map2.fitBounds(bounds);
    }


    setMarkers(markerList);
  };

  function mouseOverInspection(inspection,isRouteTable) {
    // make map pin expand
    let pin;
    if (isRouteTable) {
      pin = document.getElementById(`insr-${inspection.PolicyNo}`);
    } else {
      pin = document.getElementById(`ins-${inspection.PolicyNo}`);
    }
    if (!pin) {
      return;
    }
    // pin.style.width = "2rem";
    // pin.style.height = "2rem";
    pin.style.fontSize = "1.4rem";
    pin.style.padding = "0.9rem";
    pin.style.border = "2px solid black";
    pin.style.boxShadow = "0 0 15px 10px rgba(0, 0, 0, 0.3)";
    pin.style.zIndex = "1000";
  }

  function mouseOutInspection(inspection,isRouteTable) {
    // make map pin shrink
    let pin;
    if (isRouteTable) {
      pin = document.getElementById(`insr-${inspection.PolicyNo}`);
    } else {
      pin = document.getElementById(`ins-${inspection.PolicyNo}`);
    }
    if (!pin) {
      return;
    }
    pin.style.padding = "8px 8px";
    pin.style.fontSize = "0.8rem";
    pin.style.border = "none";
    pin.style.boxShadow = "none";
    pin.style.zIndex = "0";
  }

  const moveSelectedDate = numberOfDays => {

    setSelectedDate((prevState) => {
      const date = new Date(prevState);
      date.setDate(date.getDate() + numberOfDays);
      return date;
    });
  };



  const calculateRoute = async () => {

    // if the route date is today, warn user that this may change an route that is in progress
    const today = new Date();
    if (selectedDate.toDateString() === today.toDateString()) {
      if (!window.confirm("This will recalculate the route for today.\n\nThis route may already be in progress.\n\nAre you sure you want to recalculate it?")) {
        return;
      }
    }

    const startAddress = startLocationInput.current.value;
    const endAddress = endLocationInput.current.value;
    const desiredStartTime = startTimeInput.current.value;
    const desiredEndTime = endTimeInput.current.value;

    setRecalculationInProgress(true);
    const inspections = await apiInspections.calculateRoute(selectedInspector, selectedDate.toISOString().split('T')[0], startAddress, endAddress, startLocation, endLocation, desiredStartTime, desiredEndTime, showInspectionsWithNoAppointment);
    setRecalculationInProgress(false);
    setPlannedInspections({ route: inspections.data.result, planned: plannedInspections.planned });
  };

  const downloadRoute = () => {
    window.open(`/reports/route/csv?inspectorID=${selectedInspector}&date=${selectedDate.toISOString().split('T')[0]}`);
  };


  function getPlannedInspectionRouteNumber(inspection) {
    // see if this policy is in the route, if so return its index number
    const routeIndex = plannedInspections?.route?.RouteInspections?.findIndex(i => i.PolicyNo === inspection.PolicyNo);
    if (routeIndex > -1) {
      return routeIndex + 1;
    }
    return "";
  }


  async function doCancelAppointment(policyNo, address) {
    if (!window.confirm(`Are you sure you want to cancel the appointment for the selected inspection - ${policyNo} / ${address}`)) {
      return;
    }
    await apiInspections.cancelAppointment(policyNo);
    updateInspections();
  }

  return (
    <div>
      <h1>Route Planner</h1>

      <div ref={topSection}>
        <div className={"tw-flex tw-flex-wrap tw-gap-2 lg:tw-gap-8 tw-mb-4 tw-items-baseline"}>
          <div className={"tw-flex tw-gap-2 tw-items-baseline"}>
            <label>Date:
              <input className="tw-rounded-lg tw-ml-1" type="date" value={selectedDate.toISOString().split('T')[0]}
                onChange={e => setSelectedDate(e.target.valueAsDate)}/>
            </label>
            <i title="Back one day" className="tw-text-lg fa fa-chevron-left tw-cursor-pointer"
              onClick={() => moveSelectedDate(-1)}></i>
            <i title="Forward one day" className="tw-text-lg fa fa-chevron-right tw-cursor-pointer"
              onClick={() => moveSelectedDate(1)}></i>
          </div>
          {isManager &&
              <select className="tw-rounded-lg tw-w-96" onChange={e => setSelectedInspector(e.target.value)}>
                <option value="0">Select Inspector</option>
                {inspectors.map((inspector) =>
                  <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
                )}
              </select>
          }
          <FSButton color={"primary"} onClick={() => updateInspections()}><i className="fa fa-rotate-right"/></FSButton>
        </div>

        <hr className={"tw-bg-secondary-200 tw-h-2 tw-w-full"}/>

        {isManager && !(selectedDate && selectedInspector) &&
            <p className={"tw-text-lg"}>Please choose a date and inspector</p>}

      </div>

      <div>

        <h2>Assigned Inspections</h2>

        <div id={"map2"}
          className={`tw-my-4 2xl:tw-my-0 tw-shadow tw-rounded-xl`} style={{ height: "450px" }}>
        </div>

        <div className={"tw-my-4"}>
          <ColorKey colors={[
            {
              "title": `Assigned for ${selectedDate.toLocaleDateString()}`,
              "color": "#777777"
            },
            {
              "title": "Started",
              "color": "orange"
            },
            {
              "title": "Completed",
              "color": "#00acdc"
            }
          ]}/>
        </div>

        <table className={"table planner-inspections-table tw-flex-grow-0 text-sm"}>
          <thead>
            <tr className={"tw-bg-pink-500 tw-bg-opacity-25 tw-rounded-t-lg"}>
              <th>#</th>
              <th>Policy</th>
              <th>Insured</th>
              <th>Address</th>
              <th>Type</th>
              <th className={"tw-hidden md:tw-table-cell"}>Time</th>
              {isManager && <th></th>}
            </tr>
          </thead>
          <tbody>
            {plannedInspections?.planned?.map((inspection, idx) =>
              <tr key={inspection.PolicyNo}
                className={"odd:tw-bg-gray-200 hover:tw-bg-primary-light tw-transition tw-duration-200"}
                onMouseOver={() => mouseOverInspection(inspection, false)}
                onMouseOut={() => mouseOutInspection(inspection, false)}>
                <td>{getPlannedInspectionRouteNumber(inspection)}</td>
                <td><a target={"_blank"} href={`/inspections/${inspection.PolicyNo}/process/pre_r`}
                  rel="noreferrer">{inspection.PolicyNo}</a><br/>Age: {inspection.Age} days
                </td>
                <td>{inspection.Insured}</td>
                <td>{inspection.Address}<br/>{inspection.City}<br/>{inspection.State}, {inspection.Zip}
                </td>
                <td>
                  <p className={`tw-text-white tw-p-1 tw-w-8 tw-rounded tw-text-center`}
                    style={{
                      backgroundColor: getColorForInspection(inspection),
                      color: getTextColorForBackground(getColorForInspection(inspection))
                    }}>{inspection.Type}</p>
                  {inspection.Duration && <p className={"tw-text-center"}>{formatDuration(inspection.Duration)}</p>}
                </td>
                <td className={"tw-hidden md:tw-table-cell tw-w-96"}>
                  <InspectionWorkingTimes
                    inspection={plannedInspections?.route?.RouteInspections?.find(i => i.PolicyNo === inspection.PolicyNo) || inspection}
                    selectedDate={selectedDate}/>
                </td>
                {isManager && <td><FSButton disabled={inspection.Status !== 'new'}
                  onClick={() => doCancelAppointment(inspection.PolicyNo, inspection.Address)}>Cancel Appt.</FSButton>
                </td>}
              </tr>
            )}
          </tbody>
        </table>


        <h2>Planned Route</h2>

        <div className={`${!(selectedDate && selectedInspector) ? 'tw-hidden' : ''}`}>

          <div className={"tw-flex tw-gap-4 tw-flex-wrap tw-my-4 tw-items-center"}>
            <label>Start Address: <input ref={startLocationInput} type={"text"}
              className={"tw-rounded-lg tw-w-96 tw-ml-1"}/></label>
            <label>End Address: <input ref={endLocationInput} type={"text"}
              className={"tw-rounded-lg tw-w-96 tw-ml-1"}/></label>
            <label>Start Time: <input ref={startTimeInput} type={"time"} className={"tw-rounded-lg tw-ml-1"}/></label>
            <label>End Time: <input ref={endTimeInput} type={"time"} className={"tw-rounded-lg tw-ml-1"}/></label>
            {isManager &&
                <FSButton icon={<i className={"fa fa-calculator"}/>} color={"primary"} onClick={calculateRoute}
                  disabled={selectedDateIsInThePast}
                  submitting={recalculationInProgress}>{plannedInspections?.route?.RouteInspections?.length > 0 ? 'Recalculate' : 'Calculate'} Route</FSButton>}
            <FSButton icon={<i className={"fa fa-download"}/>} color={"primary"}
              disabled={!plannedInspections?.route?.RouteInspections?.length > 0}
              onClick={downloadRoute}>Download Route</FSButton>
            <FSButton color={"primary"} onClick={() => updateInspections()}><i
              className="fa fa-rotate-right"/></FSButton>
          </div>

        </div>

        {consolidatedInspectionList?.length > 0 && <div className={"tw-flex tw-gap-x-8"}>
          <p>Start Time: {formatTime(new Date(plannedInspections?.route?.StartTime))}</p>
          <p>End Time: {formatTime(new Date(plannedInspections?.route?.EndTime))}</p>
          <p>Route Distance: {metersToMiles(plannedInspections?.route?.Distance)} miles</p>
          <p>Inspections in Route: {plannedInspections?.route?.RouteInspections?.length}</p>
          <p>Route Created: {new Date(plannedInspections?.route?.CalculatedAt).toLocaleString()}</p>
        </div>}

        <div id={"map1"}
          className={`tw-my-4 2xl:tw-my-0 tw-shadow tw-rounded-xl`} style={{ height: "450px" }}>
        </div>

        <div className={"tw-my-4"}>
          <ColorKey colors={[
            {
              "title": `In route for ${selectedDate.toLocaleDateString()}`,
              "color": "green"
            },
            {
              "title": "Started",
              "color": "orange"
            },
            {
              "title": "Completed",
              "color": "#00acdc"
            }
          ]}/>
        </div>

        <table className={"table planner-inspections-table tw-flex-grow-0 text-sm"}>
          <thead>
            <tr className={"tw-bg-pink-500 tw-bg-opacity-25 tw-rounded-t-lg"}>
              <th>#</th>
              <th>Policy</th>
              <th>Insured</th>
              <th>Address</th>
              <th>Type</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {plannedInspections?.route?.RouteInspections?.map((inspection, idx) =>
              <tr key={inspection.PolicyNo}
                className={"odd:tw-bg-gray-200 hover:tw-bg-primary-light tw-transition tw-duration-200"}
                onMouseOver={() => mouseOverInspection(inspection, true)}
                onMouseOut={() => mouseOutInspection(inspection, true)}>
                <td>{inspection.ArrivalTime && idx + 1}.</td>
                <td><a target={"_blank"} href={`/inspections/${inspection.PolicyNo}/process/pre_r`}
                  rel="noreferrer">{inspection.PolicyNo}</a><br/>Age: {inspection.Age} days
                </td>
                <td>{inspection.Insured}</td>
                <td>{inspection.Address}<br/>{inspection.City}<br/>{inspection.State}, {inspection.Zip}
                </td>
                <td className={""}>
                  <p className={`tw-text-white tw-p-1 tw-w-8 tw-rounded tw-text-center`}
                    style={{
                      backgroundColor: getColorForInspection(inspection),
                      color: getTextColorForBackground(getColorForInspection(inspection))
                    }}>{inspection.Type}</p>
                  {inspection.Duration && <p className={""}>{formatDuration(inspection.Duration)}</p>}
                </td>
                <td className={"tw-w-96"}>
                  {inspection.ArrivalTime &&
                      <>
                        {inspection.InspectionTime !== "" &&
                            <p>{formatTime(secondsToDate(inspection.DesiredVisitWindowStart))} - {formatTime(secondsToDate(inspection.DesiredVisitWindowEnd))}</p>}
                        <p>ETA: {formatTime(new Date(inspection.ArrivalTime))}</p>
                        <InspectionWorkingTimes inspection={inspection} selectedDate={selectedDate}/>
                        <p>Distance: {metersToMiles(inspection.DistanceFromPrevious)} miles</p>
                        <p>Travel: {secondsToTime(inspection.TimeFromPrevious)}</p>
                      </>
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>
    </div>
  );


};

export default Planner;