import React, { useEffect, useMemo, useRef, useState } from 'react';
import { apiInspections } from "../api/inspections";
import { Loader } from 'google-maps';
import FSButton from "../components/Buttons/FSButton";
import * as PropTypes from "prop-types";
import { apiInspectors } from "../api/inspectors";
import { DateTime } from "luxon";
import { parseDateTimeAndChangeToLocalTimezone } from "../utilities/dateTime";
import { getRoles } from "../api/core/provider";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import ColorKey from "../components/Misc/ColorKey";
import { encodeSVG, getTextColorForBackground } from "../utilities/colours";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const formatDate = date => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

const Planner = () => {

  function getInitials(InspectorName) {
    return InspectorName.split(" ").map((n) => n[0]).join("");
  }

  function InspectionsTable({ inspections, showingCompeleteds }) {
    return (
      <table className={"table planner-inspections-table tw-flex-grow-0 text-sm"}>
        <thead>
          <tr className={"tw-bg-pink-500 tw-bg-opacity-25 tw-rounded-t-lg"}>
            <th>Policy</th>
            <th>Insured</th>
            <th>Address</th>
            <th>Type</th>
            <th>Status</th>
            <th>Assign</th>
          </tr>
        </thead>
        <tbody className={'tw-text-sm'}>
          {inspections?.map((inspection) =>
            <tr key={inspection.PolicyNo}
              className={"odd:tw-bg-gray-200 hover:tw-bg-primary-light tw-transition tw-duration-200"}
              onMouseOver={() => mouseOverInspection(inspection)}
              onMouseOut={() => mouseOutInspection(inspection)}>
              <td><a target={"_blank"} href={`/inspections/${inspection.PolicyNo}/process/pre_r`} rel="noreferrer">{inspection.PolicyNo}</a></td>
              <td>{inspection.Insured}</td>
              <td>{inspection.Address}<br/>{inspection.City}<br/>{inspection.State}, {inspection.Zip}
              </td>
              <td>
                <p className={`tw-text-white tw-p-1 tw-w-8 tw-rounded tw-text-center ${hasMissedAppointment(inspection) ? "tw-rounded-full" : ""}`}
                  style={{ backgroundColor: getColorForInspection(inspection) }}>{inspection.Type}</p>
              </td>
              {showingCompeleteds ?
                <td>Compl. {inspection.CompletedAge} days ago</td> :
                <td>{inspection.PrettyStatus}<br/>{inspection.Age} days old</td>
              }
              <td>{inspection.InspectorName && <>{getInitials(inspection.InspectorName)} <br/></>}
                {!inspection.AppointmentDate.includes("0001-01-01") && parseDateTimeAndChangeToLocalTimezone(inspection.AppointmentDate).toLocaleDateString(undefined,{ month:"short",day:"numeric" })}
                {inspection.AppointmentTime !== "All day" && <><br/>{inspection.AppointmentTime}</>}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  const isManager = getRoles().includes("manager");

  const [openInspections, setOpenInspections] = useState([]);
  const [google, setGoogle] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapInfoWindow, setMapInfoWindow] = useState(null);
  const [inspectionTypes, setInspectionTypes] = useState("Universal");
  const [selectedInspections, setSelectedInspections] = useState([]);
  const selectedInspectionsRef = useRef(selectedInspections);
  const markersRef = useRef(markers);
  const [inspectors, setInspectors] = useState([]);
  const [selectedInspector, setSelectedInspector] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointmentsFilter, setAppointmentsFilter] = useState("all");
  const [completedFilter, setCompletedFilter] = useState("open");
  const [showAges, setShowAges] = useState({ '10':true,'20':true,'30':true,'60':true });
  const [filteredInspector, setFilteredInspector] = useState(0);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [reloadingInspections, setReloadingInspections] = useState(false);

  const [assigningInspections, setAssigningInspections] = useState(false);

  const [filterDescription, setFilterDescription] = useState(null);

  useEffect(() => {
    selectedInspectionsRef.current = selectedInspections;
  }, [selectedInspections]);

  useEffect(() => {
    markersRef.current = markers;
  }, [markers]);

  const mainDiv = useRef();

  const options = {/* todo */};
  const loader = new Loader('AIzaSyBHDVtQHIMXzOr7xjqadRZtScytVh__xfA', options);

  const hasMissedAppointment = inspection => {
    if (inspection.AppointmentDate.includes("0001-01-01")) {
      return false;
    }
    const appointmentDate = DateTime.fromISO(inspection.AppointmentDate);
    const twoDaysAgo = DateTime.now().minus({ days: 2 });
    return appointmentDate < twoDaysAgo && !["completed","submitted"].includes(inspection.Status);
  };

  const getColorForInspection = inspection => {

    // if (inspection.PolicyNo === "1501-2401-9630") {
    //   console.log(selectedInspectionsRef.current);
    // }
    if (selectedInspectionsRef.current.includes(inspection.PolicyNo)) {
      return "blue";
    }

    if (["completed","submitted"].includes(inspection.Status)) {
      return "#00abcd";
    }

    // call getColorForAge, but not with any range of age - split into 5s - so 0-5 days, 6-10, 11-15 etc
    const age = inspection.Age;
    let ageRange = 0;
    if (age <= 10) {
      ageRange = 1;
    } else if (age <= 20) {
      ageRange = 11;
    } else if (age <= 30) {
      ageRange = 21;
    } else {
      ageRange = 31;
    }
    return getColorForAge(ageRange);

  };

  const getColorForAge = age => {
    let r, g, b;

    if (age <= 10) {
      // Interpolate between green (0, 205, 0) and orange (255, 165, 0)
      const localRatio = age / 10;
      r = Math.floor(0 + localRatio * (255 - 0));
      g = Math.floor(205 - localRatio * (205 - 165));
      b = 0;
    } else if (age <= 20) {
      // Interpolate between orange (255, 165, 0) and red (255, 0, 0)
      const localRatio = (age - 10) / 10;
      r = 255;
      g = Math.floor(165 - localRatio * 165);
      b = 0;
    } else if (age <= 30) {
      // Interpolate between red (255, 0, 0) and purple (128, 0, 128)
      const localRatio = (age - 20) / 10;
      r = Math.floor(255 - localRatio * (255 - 128));
      g = 0;
      b = Math.floor(0 + localRatio * 128);
    } else {
      // Interpolate between purple (128, 0, 128) and black (0, 0, 0)
      const localRatio = (age - 30) / 60;
      r = Math.floor(128 - localRatio * 128);
      g = 0;
      b = Math.floor(128 - localRatio * 128);
    }

    return `rgb(${r},${g},${b})`;
  };

  const selectInspection = (policyNo) => {
    // policyNo is passed in instead of inspection because the dynamic html in the info window can't pass any object
    const inspection = filteredInspections.find(i => i.PolicyNo === policyNo);
    let added = false;
    if (selectedInspectionsRef.current.includes(inspection)) {
      setSelectedInspections(selectedInspectionsRef.current.filter(i => i !== inspection));
    } else {
      added = true;
      setSelectedInspections([...selectedInspectionsRef.current, inspection]);
    }
    mapInfoWindow.close();

    // update color of marker to either blue or original color
    const marker = markersRef.current.find(m => m.content.id === "ins-"+inspection.PolicyNo);
    if (marker) {
      const pin = document.getElementById(`ins-${inspection.PolicyNo}`);
      if (added) {
        pin.style.backgroundColor = "blue";
      } else {
        pin.style.backgroundColor = getColorForInspection(inspection);
      }
    }
  };


  const setMainDivSize = () => {
    if (!mainDiv.current) {
      return;
    }
    const height = window.innerHeight - mainDiv.current.offsetTop - 50;
    mainDiv.current.style.height = `${height}px`;
  };

  const initMap = async () => {
    let localGoogleVar = google;
    if (!localGoogleVar) {
      localGoogleVar = await loader.load();
      setGoogle(localGoogleVar);
    }
    const map = new localGoogleVar.maps.Map(document.getElementById('map'), {
      center: { lat: 25.782479, lng: -80.2706578 },
      zoom: 8,
      mapId: '8041c9b566fb264'
    });
    setMap(map);
    setMapInfoWindow(new localGoogleVar.maps.InfoWindow());

  };

  useEffect(() => {
    const initData = async () => {
      const inspectors = await apiInspectors.get();
      setInspectors(inspectors.data.result);
    };

    initData();
    initMap();
    window.onresize = () => {
      setMainDivSize();
    };
    setMainDivSize();
  }, []);

  useEffect(() => {
    if (!filteredInspections) {
      return;
    }
    updateMapBounds();
    setMainDivSize();
  }, [map, filteredInspections]);

  function ageFilter(inspection) {
    if (completedFilter==="completed") {
      if (showAges['10'] && inspection.CompletedAge <= 10) {
        return true;
      }
      if (showAges['20'] && inspection.CompletedAge > 10 && inspection.CompletedAge <= 20) {
        return true;
      }
      if (showAges['30'] && inspection.CompletedAge > 20 && inspection.CompletedAge <= 30) {
        return true;
      }
      if (showAges['60'] && inspection.CompletedAge > 30) {
        return true;
      }
    } else {
      if (showAges['10'] && inspection.Age <= 10) {
        return true;
      }
      if (showAges['20'] && inspection.Age > 10 && inspection.Age <= 20) {
        return true;
      }
      if (showAges['30'] && inspection.Age > 20 && inspection.Age <= 30) {
        return true;
      }
      if (showAges['60'] && inspection.Age > 30) {
        return true;
      }
    }
    return false;
  }

  const doAppointmentsFilter = (inspection) => {
    if (appointmentsFilter === "all") {
      return true;
    }

    if (appointmentsFilter==="hasappt") {
      return !inspection.AppointmentDate.includes("0001-01-01") && !hasMissedAppointment(inspection);
    }

    if (appointmentsFilter==="noappt") {
      return inspection.AppointmentDate.includes("0001-01-01");
    }

    if (appointmentsFilter==="missed") {
      return hasMissedAppointment(inspection);
    }

    return false;

  };

  const updateInspections = async () => {
    const inspections = (await apiInspections.getOpenInspectionsMap(inspectionTypes,completedFilter==="completed")).data.result;
    // sort inspections by age - oldest first and then by zipcode
    inspections?.sort((a, b) => {
      if (a.Age === b.Age) {
        return a.Zip - b.Zip;
      }
      return b.Age - a.Age;
    });

    setOpenInspections(inspections);
    setMarkers([]);
    initMap();
    updateFilterDescription();
  };

  const filteredInspections = useMemo(() => {
    return openInspections?.filter(i => doAppointmentsFilter(i)).
      filter(i => ageFilter(i)).
      filter(i => filteredInspector===0 || i.InspectorID === Number(filteredInspector),
      );
  }, [openInspections]);

  // useEffect(() => {
  //   updateInspections(showNoAppointmentsOnly);
  // }, []);

  useEffect(() => {
    // this also runs on page load
    updateInspections();
  }, []);


  async function doCancelAppointment(policyNo, address) {
    if (!window.confirm(`Are you sure you want to cancel the appointment for the selected inspection - ${policyNo} / ${address}`)) {
      return;
    }
    await apiInspections.cancelAppointment(policyNo);
    updateInspections();
  }

  function createMarker(AdvancedMarkerElement, inspection) {

    const markerTag = document.createElement("div");

    markerTag.className = "inspection-map-tag";

    const tagSvgRaw = (color,textColor,label) => `
<svg width="40px" height="40px" viewBox="0 0 329 329" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-299.417,-181.468)">
        <g transform="matrix(0.591565,0.590658,-0.683963,0.685014,414.882,-180.89)">
            <rect x="348.406" y="228.564" width="275.224" height="241.594" style="fill:${color};"/>
        </g>
        <g transform="matrix(1.4404,0,0,1.4404,-212.537,-142.489)">
            <g transform="matrix(48,0,0,48,514.338,345.906)">
            </g>
            <text x="430px" y="360px" style="font-size:64px;fill:white;">${label}</text>
        </g>
    </g>
</svg>
`;

    if (hasMissedAppointment(inspection)) {
      markerTag.classList.add("inspection-map-tag-missed");
    }

    const spanTag = document.createElement("span");
    spanTag.textContent = inspection.Type;
    markerTag.appendChild(spanTag);
    markerTag.id = `ins-${inspection.PolicyNo}`;
    markerTag.style.backgroundColor = getColorForInspection(inspection);
    setAfterProperty(markerTag, "border-top-color", inspection.Type==="UE" ? "green" : "yellow");

    markerTag.style.padding = "8px 8px";
    markerTag.style.fontSize = "0.8rem";

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: inspection.Lat, lng: inspection.Lng },
      content: markerTag,
    });

    marker.addListener("click", () => {

      if (selectedInspectionsRef.current.length > 0) {
        selectInspection(inspection.PolicyNo);
        return;
      }

      window.selectInspectionFunc = selectInspection;

      let content = "";
      content = content + `<div>`;
      content = content + `<p class="tw-text-xl"><a class="" target="_blank" href="/inspections/${inspection.PolicyNo}/process/pre_r"}><strong class="tw-text-primary-500">${inspection.PolicyNo}</strong></a></p>`;
      content = content + `<p>${inspection.Insured}</p>`;
      content = content + `<p>${inspection.Address}<br/>${inspection.City}, ${inspection.County}, ${inspection.State} ${inspection.Zip}</p>`;
      content = content + `<br/`;
      content = content + `<p>Inspection Type: ${inspection.Type}</p>`;
      content = content + `<p>${inspection.Age} days old</p>`;
      content = content + `<br/`;
      content = content + `<p>Status: <strong>${inspection.PrettyStatus}</strong></p>`;
      content = content + `<p>Assigned to: ${inspection.InspectorName ? inspection.InspectorName : "n/a"}</p>`;
      content = content + `<p>Appointment Date: ${!inspection.AppointmentDate.includes("0001-01-01") ? parseDateTimeAndChangeToLocalTimezone(inspection.AppointmentDate).toLocaleDateString(undefined,{ month: 'short', day: 'numeric' }):"n/a"}</p>`;
      content = content + `<div class="tw-mt-4 tw-flex tw-justify-cente tw-gap-4">`;
      content = content + `<button class="tw-text-white tw-px-4 tw-py-2 tw-bg-primary-500 tw-uppercase" onclick="selectInspectionFunc('${inspection.PolicyNo}')">Select</button>`;
      if (isManager) {
        const hasAppointment = !inspection.AppointmentDate.includes("0001-01-01");
        content = content + `<button disabled=${hasAppointment?"false":"true"} class="tw-px-4 tw-py-2 tw-uppercase ${!hasAppointment ? "tw-bg-gray-300 tw-cursor-default" : "tw-bg-primary-500 tw-text-white"}" onclick="window.doCancelAppointmentFunc('${inspection.PolicyNo}','${inspection.Address}')">Cancel Appt.</button>`;
      }
      content = content + `</div>`;

      window.doCancelAppointmentFunc = doCancelAppointment;

      if (mapInfoWindow) {
        mapInfoWindow.setContent(content);
        mapInfoWindow.open({ anchor: marker, map });
      } else {
        console.error("mapInfoWindow not set");
      }
    });

    return marker;
  }

  // Function to set the ::after property of an element
  function setAfterProperty(element, property, value) {
    const styleSheet = document.styleSheets[0];
    const selector = `#${element.id}::after`;
    const rule = `${property}: ${value};`;

    // Check if the rule already exists
    let ruleIndex = -1;
    for (let i = 0; i < styleSheet.cssRules.length; i++) {
      if (styleSheet.cssRules[i].selectorText === selector) {
        ruleIndex = i;
        break;
      }
    }

    // If the rule exists, update it; otherwise, insert a new rule
    if (ruleIndex !== -1) {
      styleSheet.cssRules[ruleIndex].style[property] = value;
    } else {
      styleSheet.insertRule(`${selector} { ${rule} }`, styleSheet.cssRules.length);
    }
  }

  const updateMapBounds = async () => {
    if (!map) {
      return;
    }
    markersRef.current.forEach(marker => marker.setMap(null));
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const markerList = [];
    if (map && openInspections) {
      const bounds = new window.google.maps.LatLngBounds();
      // reverse order to show oldest first so that the newest pins are on top
      const reversedInspections = filteredInspections.slice().reverse();
      for (const inspection of reversedInspections) {
        const marker = createMarker(AdvancedMarkerElement, inspection);
        markerList.push(marker);
        bounds.extend(marker.position);
      }
      map.fitBounds(bounds);
      setMarkers(markerList);
      if (markerList.length>2000) {
        new MarkerClusterer({ map, markers:markerList } );
      }
    }
  };

  function mouseOverInspection(inspection) {
    // make map pin expand
    const pin = document.getElementById(`ins-${inspection.PolicyNo}`);
    if (pin) {
      pin.classList.add("tw-transform","tw-scale-200","z-10");
    }
  }

  function mouseOutInspection(inspection) {
    // make map pin shrink
    const pin = document.getElementById(`ins-${inspection.PolicyNo}`);
    if (pin) {
      pin.classList.remove("tw-transform","tw-scale-200","z-10");
    }
  }

  const assignClick = async () => {
    // confirm assignment
    if (!(selectedInspector || selectedDate)) {
      alert("Please select an inspector and / or date");
      return;
    }
    const inspectorName = inspectors.find(i => i.id === Number(selectedInspector))?.name;
    if (selectedDate && inspectorName) {
      if (!confirm(`Assign ${selectedInspections.length} inspections to ${inspectorName} with appointment on ${selectedDate}?`)) {
        return;
      }
    } else if (selectedDate && !inspectorName) {
      if (!confirm(`Set appointment for ${selectedInspections.length} inspections on ${selectedDate}?`)) {
        return;
      }
    } else {
      if (!confirm(`Assign ${selectedInspections.length} inspections to ${inspectorName}?`)) {
        return;
      }
    }

    setAssigningInspections(true);
    await apiInspections.assignInspectionsFromMap(Number(selectedInspector), selectedDate, selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections();
    setAssigningInspections(false);
  };

  const unAssignClick = async () => {
    // confirm assignment
    if (!confirm(`Unassign ${selectedInspections.length} inspections?`)) {
      return;
    }

    setAssigningInspections(true);
    await apiInspections.unAssignInspectionsFromMap(selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections();
    setAssigningInspections(false);
  };

  const cancelApptClick = async () => {
    if (!confirm(`Cancel ${selectedInspections.length} appointments?`)) {
      return;
    }

    setAssigningInspections(true);
    await apiInspections.cancelAppointments(selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections();
    setAssigningInspections(false);
  };

  const unAssignAndCancelClick = async () => {
    if (!confirm(`Unassign and cancel appointment for ${selectedInspections.length} inspections?`)) {
      return;
    }

    setAssigningInspections(true);
    await apiInspections.unAssignInspectionsFromMap(selectedInspections.map(i => i.PolicyNo));
    await apiInspections.cancelAppointments(selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections();
    setAssigningInspections(false);
  };

  // const sortedInspections = openInspections?.toSorted((a,b) => {
  //   // sort by age, then by zip
  //   // put any selected inspection at the top, maintaining the age and zip
  //   if (selectedInspectionsRef.current.includes(a) && !selectedInspectionsRef.current.includes(b)) {
  //     return -1;
  //   }
  //   if (!selectedInspectionsRef.current.includes(a) && selectedInspectionsRef.current.includes(b)) {
  //     return 1;
  //   }
  //
  //   if (a.Age === b.Age) {
  //     return a.Zip - b.Zip;
  //   }
  //   return b.Age - a.Age;
  //
  // });

  const clearSelection = () => {
    // call select inspection on each selected to clear the map coloring
    selectedInspections.forEach((i) => selectInspection(i.PolicyNo));
    // then clear the main list
    setSelectedInspections([]);
  };

  function getLabelForAgeCheck(age) {
    switch (age) {
    case '10':
      return "0-10 days";
    case '20':
      return "11-20 days";
    case '30':
      return "21-30 days";
    case '60':
      return "31+ days";
    }
  }

  const ageFilterItems = useMemo(() => {
    if (completedFilter==="completed") {
      return ['10','20','30'];
    }
    return ['10','20','30','60'];
  },[completedFilter]);

  const inspectorsWithInspections = inspectors.filter((i) => {
    for (const inspection of openInspections) {
      if (inspection.InspectorID === i.id) {
        return true;
      }
    }
  });

  const applyFilterChange = async () => {
    setReloadingInspections(true);
    await updateInspections();
    setFilterDialogOpen(false);
    setReloadingInspections(false);
  };

  const updateFilterDescription = () => {
    let parts = [];
    parts.push(`Insurance Company: ${inspectionTypes}`);
    if (appointmentsFilter==="missed") {
      parts.push("Missed Appointment (>2 days no complete)");
    } else if (appointmentsFilter==="noappt") {
      parts.push("No Appointment");
    } else if (appointmentsFilter==="hasappt") {
      parts.push("With Appointment (not missed)");
    }
    if (completedFilter==="completed") {
      parts.push("Completed Inspections");
    }
    if (showAges['10'] && showAges['20'] && showAges['30'] && showAges['60']) {
      parts.push("All Ages");
    } else {
      parts.push("Ages: " + Object.keys(showAges).filter(k => showAges[k]).map(k => getLabelForAgeCheck(k)).join(", "));
    }
    if (filteredInspector>0) {
      parts.push(`Inspector: ${inspectors.find(i => i.id === Number(filteredInspector))?.name}`);
    }
    setFilterDescription(parts.join(" / "));
  };

  return (
    <>
      <div>
        <h1>Global Open Inspections</h1>

        <div className={"tw-flex tw-flex-wrap tw-gap-8 tw-mb-4 tw-items-baseline"}>

          <FSButton onClick={() => setFilterDialogOpen(true)}>Filters...</FSButton>

          <p>{filterDescription}</p>

        </div>

        <div className={"tw-flex tw-gap-16 tw-items-center"}>
          {selectedInspections.length===0 && <p className={"tw-text-xl"}>Displayed Inspections - {filteredInspections?.length}</p>}
          <ColorKey colors={[
            {
              "title": `Selected`,
              "color": "blue"
            },
            {
              "title": "Completed",
              "color": "#00acdc"
            },
            {
              "title": "Aged 0-10 days",
              "color": getColorForAge(1)
            },
            {
              "title": "Aged 11-20 days",
              "color": getColorForAge(11)
            },
            {
              "title": "Aged 21-30 days",
              "color": getColorForAge(21)
            },
            {
              "title": "Aged 31+ days",
              "color": getColorForAge(31)
            },
            {
              "title":"Missed Appointment",
              "color":"black",
              "shape":"circle"
            }
          ]}/>
        </div>
        <div ref={mainDiv}
          // className={`${openInspections?.length > 0 ? "xl:tw-grid tw-grid-cols-3 xl:tw-gap-4" : "tw-hidden"} tw-my-4`}>
          className={`xl:tw-grid tw-grid-cols-3 xl:tw-gap-4 tw-my-4`}>
          <div className={"tw-overflow-y-scroll"}>
            {selectedInspections.length > 0 &&
                <div className={"tw-mb-4"}>
                  <div className={"tw-flex tw-items-center tw-gap-8"}>
                    <p className={"tw-text-xl"}>Selected {selectedInspections.length === 1 ? "inspection" : "inspections"} - {selectedInspections.length}</p>
                    <FSButton onClick={clearSelection}>clear</FSButton>
                  </div>
                  <InspectionsTable inspections={selectedInspections}
                    showingCompeleteds={completedFilter === "completed"}/>

                  <div className={"tw-flex tw-items-center tw-justify-between tw-gap-4"}>
                    <div className={"tw-flex tw-gap-2 tw-items-baseline"}>
                      <input className="tw-rounded-lg" type="date" value={selectedDate}
                        onChange={e => setSelectedDate(e.target.value)}/>
                    </div>
                    <select className="tw-rounded-lg tw-flex-grow" onChange={e => setSelectedInspector(e.target.value)}>
                      <option value="0">Select Inspector</option>
                      {inspectors.map((inspector) =>
                        <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
                      )}
                    </select>
                    <FSButton disabled={!(selectedInspector > 0 || selectedDate)} submitting={assigningInspections}
                      onClick={assignClick}>Apply</FSButton>
                  </div>
                  <div className={"tw-flex tw-justify-between"}>
                    <FSButton submitting={assigningInspections} onClick={unAssignClick}>Unassign</FSButton>
                    <FSButton submitting={assigningInspections} onClick={cancelApptClick}>Cancel Appt.</FSButton>
                    <FSButton submitting={assigningInspections} onClick={unAssignAndCancelClick}>Unassign & Cancel
                      Appt.</FSButton>
                  </div>

                </div>
            }

            {selectedInspections.length>0 && <p className={"tw-text-xl"}>Displayed Inspections - {filteredInspections?.length}</p>}
            <InspectionsTable inspections={filteredInspections} showingCompeleteds={completedFilter === "completed"}/>
          </div>
          <div id={"map"}
            className={`tw-my-4 2xl:tw-my-0 tw-shadow tw-overflow-hidden tw-rounded-xl tw-w-full tw-col-span-2 tw-max-h-screen`}>
          </div>
        </div>

      </div>

      <Dialog fullWidth={false} maxWidth={"md"} open={filterDialogOpen} onClose={() => setFilterDialogOpen(false)}>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent className={"tw-flex tw-justify-center"}>
          <div className={"tw-grid tw-grid-cols-3 tw-gap-4"}>
            <p className={"tw-font-bold"}>Inspection Types</p>
            <div className={"tw-flex tw-gap-4 tw-col-span-2"}>
              <label>
                <input type={"radio"} name="type" value={"Orchid"}
                  checked={inspectionTypes === "Orchid"} onChange={() => setInspectionTypes("Orchid")}/>
                Orchid
              </label>
              <label>
                <input type={"radio"} name="type" value={"Universal"} checked={inspectionTypes === "Universal"}
                  onChange={() => setInspectionTypes("Universal")}/>
                Universal
              </label>
              <label>
                <input type={"radio"} name="type" value={"All"} checked={inspectionTypes === "All"}
                  onChange={() => setInspectionTypes("All")}/>
                All
              </label>
            </div>
            <p className={"tw-font-bold"}>Appointments</p>
            <div className={"tw-flex tw-gap-4 tw-col-span-2"}>
              <label>
                <input type={"radio"} name="appointmentsradio" value={"all"} checked={appointmentsFilter === "all"}
                  onClick={() => setAppointmentsFilter("all")}/>
                All
              </label>
              <label>
                <input type={"radio"} name="appointmentsradio" value={"noappt"} checked={appointmentsFilter === "noappt"}
                  onClick={() => setAppointmentsFilter("noappt")}/>
                No appt.
              </label>
              <label>
                <input type={"radio"} name="appointmentsradio" value={"hasappt"} checked={appointmentsFilter === "hasappt"}
                  onClick={() => setAppointmentsFilter("hasappt")}/>
                Has appt.
              </label>
              <label>
                <input type={"radio"} name="appointmentsradio" value={"missed"} checked={appointmentsFilter === "missed"}
                  onClick={() => setAppointmentsFilter("missed")}/>
                Missed appt.
              </label>

            </div>
            <p className={"tw-font-bold"}>Open or Completed?</p>
            <div className={"tw-flex tw-gap-4 tw-col-span-2"}>
              <label>
                <input type={"radio"} name="openorcompleted" value={"open"} checked={completedFilter === "open"}
                  onClick={() => setCompletedFilter("open")}/>
                Open
              </label>
              <label>
                <input type={"radio"} name="openorcompleted" value={"completed"}
                  checked={completedFilter === "completed"} onClick={() => setCompletedFilter("completed")}/>
                Completed
              </label>
            </div>
            <p className={"tw-font-bold"}>Age</p>
            <div className={"tw-flex tw-gap-4 tw-col-span-2"}>
              {ageFilterItems.map((age) =>
                <label className={"tw-pr-2"} key={age}>
                  <input className={"tw-pl-2"} type={"checkbox"} checked={showAges[age]}
                    onClick={(e) => setShowAges({ ...showAges, [age]: e.target.checked })}/>
                  {getLabelForAgeCheck(age)}
                </label>
              )}
            </div>
            <p className={"tw-font-bold"}>Inspector</p>
            <div className={"tw-col-span-1"}>
              <select className={""} value={filteredInspector}
                onChange={e => setFilteredInspector(Number(e.target.value))}>
                <option value={0}>All</option>
                {inspectorsWithInspections.map((inspector) =>
                  <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
                )}
              </select>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <FSButton submitting={reloadingInspections} onClick={() => applyFilterChange()}>Apply</FSButton>
          <FSButton onClick={() => setFilterDialogOpen(false)}>Cancel</FSButton>
        </DialogActions>
      </Dialog>


    </>
  );

};

export default Planner;